<template>
  <div class="product-containter">
    <nav-menu></nav-menu>
    <div class="product-body">
      <div class="product-apply flex-a flex-b">
        <div class="product-left">
          <div>电子保函</div>
          <div>纯信用免保证金、额度高可循环、线上办、费率低、秒开立</div>
        </div>
        <div class="product-right">立即申请</div>
      </div>
      <div class="product-list flex-a">
        <div class="title">产品类别</div>
        <div :class="code == item.code ? 'product-item active' : 'product-item'" v-for="item in tabList" :key="item.id"
          @click="changeTab(item.code)">{{ item.name }}</div>
      </div>
      <div class="containter flex">
        <div class="bank-list">
          <div class="bank-item" v-for="item in list" :key="item.id">
            <div><img :src="item.logo" alt="" /></div>
            <div class="text">{{ item.introduce }}</div>
            <div><span @click="checkDetail(item.id)">查看</span></div>
          </div>
          <div class="page-list" v-if="list.length > 0">
            <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange" :total="total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import { getProList, getProListByToken, getProduct, getProductByToken } from './../lib/api/product'
export default {
  name: 'product',
  data() {
    return {
      code: '',
      total: 0,
      list: [],
      tabList: []
    }
  },
  created() {
    if (window.localStorage.getItem("token")) {
      this.getProListByToken()// 金融产品分类-登录
    } else {
      this.getProList()// 金融产品分类-未登录
    }
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    // 金融产品分类-未登录
    getProList() {
      getProList(0).then(res => {
        if (res.code == 200) {
          this.tabList = res.data
          this.code = res.data[0].code
          this.getProduct({ pageNum: 1, pageSize: 10, code: this.code })//产品-未登录
        }
      })
    },
    // 金融产品分类-登录
    getProListByToken() {
      getProListByToken(0).then(res => {
        if (res.code == 200) {
          this.tabList = res.data
          this.code = res.data[0].code
          this.getProductByToken({ pageNum: 1, pageSize: 10, code: this.code })//产品-登录
        }
      })
    },
    //产品-登录
    getProductByToken(params) {
      getProductByToken(params).then(res => {
        if (res.code == 200) {
          this.list = res.rows
          this.total = res.total
        }
      })
    },
    //产品-未登录
    getProduct(params) {
      getProduct(params).then(res => {
        if (res.code == 200) {
          this.list = res.rows
          this.total = res.total
        }
      })
    },
    //切换
    changeTab(code) {
      this.code = code
      if (window.localStorage.getItem("token")) {
        this.getProductByToken({ pageNum: 1, pageSize: 10, code: this.code })//产品-登录
      } else {
        this.getProduct({ pageNum: 1, pageSize: 10, code: this.code })//产品-未登录
      }
    },
    //立即申请
    apply() {
      this.$router.push({ path: '/project', query: { type: 0 } })
    },
    // 查看详情
    checkDetail(id) {
      this.$router.push({ path: '/product_detail', query: { id: id, code: this.code } })
    },
    //当前页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      if (window.localStorage.getItem("token")) {
        this.getProductByToken({ pageNum: val, pageSize: 10, code: this.code })//产品-登录
      } else {
        this.getProduct({ pageNum: val, pageSize: 10, code: this.code })//产品-未登录
      }
    },
  },
  computed: {

  }
}
</script>

<style lang="less" scoped>
.product-containter {
  background: #F0F2F5;
  min-height: 100vh;

  .product-body {
    padding: 20px 0;

    .product-apply {
      box-sizing: border-box;
      width: 60%;
      margin: 0 auto 10px;
      background: url(./../assets/product_bg.jpg)no-repeat 0 0;
      background-size: 100%;
      color: #fff;
      font-size: 18px;

      .product-left {
        margin: 20px 50px;

        div:first-child {
          margin-bottom: 10px;
          font-weight: bold;
          font-size: 26px;
        }
      }

      .product-right {
        margin: 50px 80px 0 0;
        background: url(./../assets/btn_bg.png)no-repeat 0 0;
        background-size: 100%;
        width: 180px;
        height: 100px;
        padding-left: 45px;
        box-sizing: border-box;
        line-height: 40px;
        font-size: 15px;
      }

      // .product-right:hover {
      //   cursor: pointer;
      // }
    }

    .product-list {
      box-sizing: border-box;
      width: 60%;
      margin: 0 auto 20px;

      .title {
        font-weight: bold;
        margin-right: 60px;
        white-space: nowrap;
      }

      .product-item {
        color: #313543;
        min-width: 120px;
        height: 25px;
        padding: 0 5px;
        box-sizing: border-box;
        text-align: center;
        line-height: 25px;
        margin-right: 20px;
        border: 1px solid transparent;
        font-size: 14px;
        white-space: nowrap;
      }

      .product-item.active {
        color: #338BF3;
        border-color: #338BF3;
        border-radius: 20px;
        background: #EDF7FF;
      }

      .product-item:hover {
        cursor: pointer;
      }
    }

    .containter {
      padding: 20px;

      .bank-list {
        width: 100%;

        .bank-item {
          display: inline-block;
          border: 1px solid #DCDCDC;
          text-align: center;
          width: 32%;
          height: 280px;
          padding: 10px 16px;
          transition: all .15s linear;
          vertical-align: top;
          box-sizing: border-box;
          margin-bottom: 20px;
          position: relative;

          div:first-child {
            width: 100%;
            height: 90px;
            text-align: center;
            position: relative;

            img {
              width: 70%;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }

          .text {
            font-size: 15px;
            color: #666;
            line-height: 24px;
            overflow: hidden;
            margin-top: 5px;
            text-align: left;
          }

          span {
            box-sizing: border-box;
            display: block;
            margin: 0 auto;
            width: 80px;
            text-align: center;
            height: 30px;
            line-height: 28px;
            border-radius: 25px;
            margin-top: 25px;
            font-size: 14px;
            color: #2979dc;
            border: 1px solid #2979dc;
            transition: all .15s linear;
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
          }

          span:hover {
            cursor: pointer;
            color: #fff;
            background: #2979dc;
          }
        }

        .bank-item:hover {
          border-color: #2979dc;
          box-shadow: 0 0 10px #E7ECF2;
        }

        .bank-item:nth-child(3n+2) {
          margin: 0 2%;
        }

        .page-list {
          margin: 20px auto;
          text-align: center;
        }
      }
    }
  }
}
</style>
